<template>
  <vx-card style="padding-left: 10px">
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="firstName">First Name</label>
          <vs-input
            data-vv-validate-on="blur"
            v-validate="'required|max:50'"
            name="firstName"
            maxlength="50"
            icon-no-border
            icon="icon icon-user"
            icon-pack="feather"
            v-model="userDetail.firstName"
            class="w-full"
          />
          <span class="text-danger text-sm">{{
            errors.first("firstName")
          }}</span>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="lastName">Last Name</label>
          <vs-input
            data-vv-validate-on="blur"
            v-validate="'required|max:50'"
            name="lastName"
            maxlength="50"
            icon-no-border
            icon="icon icon-user"
            icon-pack="feather"
            v-model="userDetail.lastName"
            class="w-full"
          />
          <span class="text-danger text-sm">{{
            errors.first("lastName")
          }}</span>
        </div>
      </vs-col>
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="email">Email</label>
          <vs-input
            v-validate="'required|email|min:3'"
            data-vv-validate-on="blur"
            name="email"
            icon-no-border
            icon="icon icon-mail"
            icon-pack="feather"
            v-model="userDetail.email"
            class="w-full"
            :disabled="userDetail._id"
          />
          <span class="text-danger text-sm">{{ errors.first("email") }}</span>
          <!-- <span class="text-danger text-sm">{{ msgInUse }}</span> -->
        </div>
      </vs-col>

      <vs-row class="flex flex-wrap items-center p-6">
        <vs-button
          class="mr-6"
          color="danger"
          type="flat"
          @click="$router.replace('/admin/user-management')"
          >Cancel</vs-button
        >
        <vs-button
          color="primary"
          v-if="!userDetail._id"
          @click="addNewUserDetail"
          >Save</vs-button
        >
      </vs-row>
    </vs-row>
  </vx-card>
</template>

  <script>
import { mapActions } from "vuex";
import moment from "moment";
import { Validator } from "vee-validate";
import vSelect from "vue-select";
import { json } from "body-parser";
import { userMixin } from "../../mixins/userMixin";

export default {
  name: "AddUser",
  components: {
    "v-select": vSelect,
  },
  mixins: [userMixin],
  data: () => ({
    userDetail: {}
  }),
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.userDetail.email != "" &&
        this.userDetail.firstName != "" &&
        this.userDetail.lastName != ""
      );
    },
  },
  methods: {
    addNewUserDetail() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.addUser(this.userDetail)
            .then((res) => {
              if (res.message) {
                if(res.message.code===200){
                  this.$vs.notify({
                    title: "User Added",
                    text: res.message.message,
                    iconPack: "feather",
                    icon: "icon-alert-circle",
                    color: "success",
                  });
                  this.$router.push({ name: 'AppUsers' })
                }else{
                  this.$vs.notify({
                    title: "Error",
                    text: res.message.message,
                    iconPack: "feather",
                    icon: "icon-alert-circle",
                    color: "danger",
                  });
                }

                /*
                this.$router.push(
                  `/admin/user-management/edit/${res.data.data._id}`
                );
                this.getUserDetail(res.data.data._id);*/
              } else {
                //for email already i use
                this.$vs.notify({
                  title: "Error",
                  text: res.data.message,
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "danger",
                });
                // this.msgInUse = res.data.message;
              }
            })
            .catch((e) => {
              this.$vs.notify({
                title: "Error",
                text: e.response.data.message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
              });
            });
        }
      });
    },
  },
  created() {
  },
};
</script>
<style>
.ql-editor {
  min-height: 200px;
}
</style>
